<template>
  <v-row>
    <v-col cols="12">
      <report-detail></report-detail>
    </v-col>
  </v-row>
</template>

<script>
import ReportDetail from './components/ReportDetail.vue'

export default {
  components: {
    ReportDetail,
  },
  setup() {
    return {}
  },
}
</script>
