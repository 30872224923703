<template>
  <div class="row">
    <div v-if="isLoading">
      <div class="d-flex justify-center">
        <v-skeleton-loader
            class="mx-auto pa-1"
            width="1200"
            type="card"
          ></v-skeleton-loader>
      </div>
    </div>
    <div class="full-width" v-else>
      <!-- DESKTOP -->
      <div class="headline black--text font-weight-bold d-flex" v-if="!isMobile()">
        Report {{ itemData[0].invoice}} 
        <v-btn color="primary" class="text-capitalize ml-auto" @click.prevent="downloadFile(itemData[0].invoice)"><i class="fas fa-download mr-2"></i> Download Excel</v-btn>
      </div>
      <!-- MOBILE -->
      <div class="body-1 black--text font-weight-bold" v-else>
        Report {{ itemData[0].invoice}}
        <v-btn block color="primary" class="text-capitalize ml-auto mt-2" @click.prevent="downloadFile(itemData[0].invoice)"><i class="fas fa-download mr-2"></i> Download Excel</v-btn>
      </div>

      <!-- DESKTOP -->
      <div v-if="!isMobile()">
        <v-row class="mt-0">
          <div class="col-md-4 col-lg-12">
            <v-card class="overflow-hidden elevation-1">
              <!-- <v-card-title class="subtitle-1 font-weight-bold">Total Influencers {{ itemData.length }}</v-card-title> -->
              <v-card-actions class="d-flex justify-center subtitle-2">
                <div class="col-md-3">
                  <p class="d-flex align-center mb-0">
                    <i class="far fa-user secondary--text text-24 mr-2"></i>
                    <v-chip 
                      class="caption font-weight-bold text-capitalize"
                      color="secondary"
                    >{{ itemData.length}} Influencer
                    </v-chip>
                  </p>
                </div>
                <div class="col-md-3">
                  <p class="d-flex align-center mb-0">
                    <!-- <b-icon icon="bar-chart" class="primary--text text-24 mr-2"></b-icon> -->
                    <i class="fas fa-signal secondary--text text-24 mr-2"></i>
                    <v-chip 
                      class="caption font-weight-bold text-capitalize"
                      color="secondary"
                      v-for="lvl in level" :key="lvl.id"
                    >
                      {{ lvl }}
                    </v-chip>
                  </p>
                </div>
                <div class="col-md-3">
                  <p class="d-flex align-center mb-0">
                    <i class="fas fa-bullhorn secondary--text text-24 mr-2"></i>
                    <v-chip 
                      class="caption font-weight-bold text-capitalize"
                      color="secondary"
                    >Total Impressions {{ impressions | numberFormat}}
                    </v-chip>
                  </p>
                </div>
                <div class="col-md-3">
                  <p class="d-flex align-center mb-0">
                    <i class="fas fa-bullhorn secondary--text text-24 mr-2"></i>
                    <v-chip 
                      class="caption font-weight-bold text-capitalize"
                      color="secondary"
                    >Total Reach {{ reach | numberFormat}}
                    </v-chip>
                  </p>
                </div>
              </v-card-actions>
            </v-card>
          </div>
        </v-row>
        <v-row class="mt-0">
          <div class="col-md-4 col-lg-12">
            <v-card class="overflow-hidden elevation-1">
              <!-- <v-card-title class="subtitle-1 font-weight-bold">Total Influencers {{ itemData.length }}</v-card-title> -->
              <v-card-actions class="d-flex justify-center subtitle-2">
                <div class="col-md-3">
                  <p class="d-flex align-center mb-0">
                    <i class="far fa-heart secondary--text text-24 mr-2"></i>
                    <v-chip 
                      class="caption text-capitalize"
                      color="secondary"
                    >Total Likes <span class="font-weight-bold ml-1">{{ likes | numberFormat}}</span>
                    </v-chip>
                  </p>
                </div>
                <div class="col-md-3">
                  <p class="d-flex align-center mb-0">
                    <!-- <b-icon icon="bar-chart" class="primary--text text-24 mr-2"></b-icon> -->
                    <i class="far fa-comment secondary--text text-24 mr-2"></i>
                    <v-chip 
                      class="caption text-capitalize"
                      color="secondary"
                    >Total Comments <span class="font-weight-bold ml-1">{{ comments | numberFormat}}</span>
                    </v-chip>
                  </p>
                </div>
                <div class="col-md-3">
                  <p class="d-flex align-center mb-0">
                    <i class="far fa-paper-plane secondary--text text-24 mr-2"></i>
                    <v-chip 
                      class="caption text-capitalize"
                      color="secondary"
                    >Total Share <span class="font-weight-bold ml-1">{{ share | numberFormat}}</span>
                    </v-chip>
                  </p>
                </div>
                <div class="col-md-3">
                  <p class="d-flex align-center mb-0">
                    <i class="far fa-bookmark secondary--text text-24 mr-2"></i>
                    <v-chip 
                      class="caption text-capitalize"
                      color="secondary"
                    >Total Saved <span class="font-weight-bold ml-1">{{ saved | numberFormat}}</span>
                    </v-chip>
                  </p>
                </div>
              </v-card-actions>
            </v-card>
          </div>
        </v-row>
        <v-card color="transparent" class="elevation-0 mt-3">
          <!-- <v-card-title class="font-weight-medium pl-0"><div>Report {{itemData[0].invoice}}</div></v-card-title> -->
          <v-data-table
              :headers="headers"
              :items="itemData"
              item-key="id"
              class="elevation-1 text-capitalize"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer
              @page-count="pageCount = $event"
              :loading="loadingTable"
              loading-text="Loading... Memuat Data"
          >
            <template v-slot:[`item.iron`]="{ item }">
                <v-avatar class="ma-2">
                    <v-img :src="item.iron" :alt="item.iron" height="100px"></v-img>
                </v-avatar>
            </template>
            <template v-slot:[`item.categories`]="{ item }">
              <div>
                <v-chip 
                  class="ma-1 caption"
                  color="secondary"
                  v-for="category in item.categories" :key="category.id"
                >
                  {{ category.name }}
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.follower_ig`]="{ item }">
              <div v-if="item.follower_ig > 0">
                {{ item.follower_ig | numberFormat }}
              </div>
              <div v-else>
                {{ item.follower_ig }}
              </div>
            </template>
            <template v-slot:[`item.reach`]="{ item }">
              <div v-if="item.reach > 0">
                {{ item.reach | numberFormat }}
              </div>
              <div v-else>
                {{ item.reach }}
              </div>
            </template>
            <template v-slot:[`item.impressions`]="{ item }">
              <div v-if="item.impressions > 0">
                {{ item.impressions | numberFormat }}
              </div>
              <div v-else>
                {{ item.impressions }}
              </div>
            </template>
            <template v-slot:[`item.likes`]="{ item }">
              <div v-if="item.likes > 0">
                {{ item.likes | numberFormat }}
              </div>
              <div v-else>
                {{ item.likes }}
              </div>
            </template>
            <template v-slot:[`item.comments`]="{ item }">
              <div v-if="item.comments > 0">
                {{ item.comments | numberFormat }}
              </div>
              <div v-else>
                {{ item.comments }}
              </div>
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              <div v-if="item.gender === 'L'">
                Laki-laki
              </div>
              <div v-else>
                Perempuan
              </div>
            </template>
          </v-data-table>
          <div class="text-center mt-2 pt-0">
            <v-pagination
                v-model="page"
                :length="pageCount"
            ></v-pagination>
          </div>
        </v-card>
      </div>
      <!-- MOBILE -->
      <div v-else>
        <v-row class="mt-0">
          <div class="col-sm-12">
            <v-card class="overflow-hidden elevation-1">
              <div class="row ma-2">
                <div class="col-sm-12 pb-0 d-flex">
                  <p class="mb-0 pr-1">
                    <v-chip color="secondary" class="caption secondary--text">
                    <i class="far fa-user white--text mr-1"></i>
                      {{ itemData.length}} Influencer
                    </v-chip>
                  </p>
                  <p class="mb-0 secondary--text">
                    <v-chip
                      class="caption text-capitalize mr-1"
                      color="secondary"
                      v-for="lvl in level" :key="lvl.id"
                    >
                    <i class="fas fa-signal white--text mr-1"></i>
                      {{ lvl }}
                    </v-chip>
                  </p>
                </div>
                <div class="col-sm-12 pt-2 pb-0 d-flex">
                  <p class="mb-0 secondary--text pr-1">
                    <v-chip
                      class="caption text-capitalize"
                      color="secondary"
                    >
                    <i class="fas fa-bullhorn white--text mr-1"></i>
                    {{ impressions | numberFormat}} Impressions
                    </v-chip>
                  </p>
                  <p class="mb-0 secondary--text">
                    <v-chip 
                      class="caption text-capitalize"
                      color="secondary"
                    >
                    <i class="fas fa-bullhorn white--text  mr-1"></i>
                    {{ reach | numberFormat}} Reach
                    </v-chip>
                  </p>
                </div>
                <div class="col-sm-12 pt-2 pb-0 d-flex">
                  <p class="mb-0 pr-1">
                    <v-chip 
                      class="caption text-capitalize"
                      color="secondary"
                    >
                    <i class="far fa-heart white--text mr-2"></i>
                    {{ likes | numberFormat}} Likes
                    </v-chip>
                  </p>
                  <p class="d-flex align-center mb-0">
                    <v-chip 
                      class="caption text-capitalize"
                      color="secondary"
                    >
                    <i class="far fa-comment white--text mr-2"></i>
                    {{ comments | numberFormat}} Comments
                    </v-chip>
                  </p>
                </div>
                <div class="col-sm-12 pt-2 pb-3 d-flex">
                  <p class="d-flex align-center mb-0 pr-1">
                    <v-chip 
                      class="caption text-capitalize"
                      color="secondary"
                    >
                    <i class="far fa-paper-plane white--text mr-2"></i>
                    {{ share | numberFormat}} Share
                    </v-chip>
                  </p>
                  <p class="d-flex align-center mb-0">
                    <v-chip 
                      class="caption text-capitalize"
                      color="secondary"
                    >
                    <i class="far fa-bookmark white--text mr-2"></i>
                    {{ saved | numberFormat}} Saved
                    </v-chip>
                  </p>
                </div>
              </div>
            </v-card>
          </div>
        </v-row>
        <v-card color="transparent" class="elevation-0 mt-3">
          <!-- <v-card-title class="font-weight-medium pl-0"><div>Report {{itemData[0].invoice}}</div></v-card-title> -->
          <v-data-table
              :headers="headers"
              :items="itemData"
              item-key="id"
              class="elevation-1 text-capitalize"
              :page.sync="page"
              :items-per-page="itemsPerPageMobile"
              hide-default-footer
              @page-count="pageCount = $event"
              :loading="loadingTable"
              loading-text="Loading... Memuat Data"
          >
            <template v-slot:[`item.iron`]="{ item }">
                <v-avatar class="ma-2">
                    <v-img :src="item.iron" :alt="item.iron" height="100px"></v-img>
                </v-avatar>
            </template>
            <template v-slot:[`item.categories`]="{ item }">
              <div>
                <v-chip 
                  class="ma-1 caption"
                  color="secondary"
                  v-for="category in item.categories" :key="category.id"
                >
                  {{ category.name }}
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.follower_ig`]="{ item }">
              <div v-if="item.follower_ig > 0">
                {{ item.follower_ig | numberFormat }}
              </div>
              <div v-else>
                {{ item.follower_ig }}
              </div>
            </template>
            <template v-slot:[`item.reach`]="{ item }">
              <div v-if="item.reach > 0">
                {{ item.reach | numberFormat }}
              </div>
              <div v-else>
                {{ item.reach }}
              </div>
            </template>
            <template v-slot:[`item.impressions`]="{ item }">
              <div v-if="item.impressions > 0">
                {{ item.impressions | numberFormat }}
              </div>
              <div v-else>
                {{ item.impressions }}
              </div>
            </template>
            <template v-slot:[`item.likes`]="{ item }">
              <div v-if="item.likes > 0">
                {{ item.likes | numberFormat }}
              </div>
              <div v-else>
                {{ item.likes }}
              </div>
            </template>
            <template v-slot:[`item.comments`]="{ item }">
              <div v-if="item.comments > 0">
                {{ item.comments | numberFormat }}
              </div>
              <div v-else>
                {{ item.comments }}
              </div>
            </template>
          </v-data-table>
          <div class="text-center mt-2 pt-0">
            <v-pagination
                v-model="page"
                :length="pageCount"
            ></v-pagination>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import axios from 'axios'

export default defineComponent({
  components: {
  },
  setup() {
    return{
      totalPrice:0,
      itemData:[],
      isLoading:true,
      loadingTable:true,
      total:0,
      serviceFee:0,
      order:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      itemsPerPageMobile: 5,
      index:0,
      level:[],
      impressions:0,
      reach:0,
      likes:0,
      comments:0,
      share:0,
      saved:0
    }
  },
  mounted(){
    let id = this.$route.query.order
    this.order = this.decryptData(id)

    if(parseInt(this.order) > 0){
      this.loadOrders()
    } else {
      this.$router.push({name:'report'});
    }
  },
  computed: {
    headers() {
      return [
        {text: 'No.', value: 'num', sortable: false, width: "1%"},
        // {text: 'Paket', value: 'packages.name', sortable: false, width: "1%"},
        {text: 'Gambar', value: 'iron', sortable: false, width: "0.5%"},
        {text: 'Influencer', value: 'name', sortable: false, width: "1%"},
        {text: 'Follower', align: 'left', sortable: false, value: 'follower_ig', width: "1%"},
        {text: 'Upload', value: 'upload_date', sortable: false, width: "11%"},
        {text: 'Konten', value: 'packages.content', sortable: false, width: "30%"},
        {text: 'Reach', align: 'center', value: 'reach', sortable: false, width: "1%"},
        {text: 'Impressions',align: 'center',  value: 'impressions', sortable: false, width: "1%"},
        {text: 'Likes', align: 'center', value: 'likes', sortable: false, width: "1%"},
        {text: 'Comments', align: 'center', value: 'comments', sortable: false, width: "1%"},
        {text: 'Share', align: 'center', value: 'share', sortable: false, width: "1%"},
        {text: 'Saved', align: 'center', value: 'saved', sortable: false, width: "1%"},
        {text: 'Link Click', align: 'center', value: 'link_click', sortable: false, width: "1%"},
        // {text: 'Play', value: 'play', sortable: false, width: "1%"},
        // {text: 'Sticker Tap', value: 'sticker_taps', sortable: false, width: "1%"},
      ]
    },
  },
  methods:{
    decryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg=';
      let decryptData = this.$CryptoJS.AES.decrypt(val, secretKey).toString(this.CryptoJS.enc.Utf8);
      return decryptData;
    },
    loadOrders(){
      this.isLoading = true;
      this.loadingTable = true
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'requestCampaign/getOrderDetail/'+this.order, {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response.status === 200){
            this.itemData = response.data.data
            for(let a = 0; a < this.itemData.length; a++){
              this.itemData[a].date_added = new Date(this.itemData[a].date_added)
              this.itemData[a].upload_date = this.itemData[a].upload_date.slice(0,10)
              // for(let b=0; b < this.itemData[a].order_detail.length; b++){
                this.itemData[a].num = a+1
                if(this.itemData[a].type === 'custom'){
                  this.itemData[a].packages.description = this.itemData[a].packages.type
                  this.itemData[a].packages.type = this.itemData[a].packages.level
                  this.itemData[a].packages.content = this.itemData[a].packages.format
                  this.itemData[a].packages.name = this.itemData[a].packages.name.slice(0,6)
                }
                this.level.push(this.levelFormat(this.itemData[a].level))
                this.impressions += this.itemData[a].impressions ? parseInt(this.itemData[a].impressions) : 0
                this.reach += this.itemData[a].reach ? parseInt(this.itemData[a].reach) : 0
                this.likes += this.itemData[a].likes ? parseInt(this.itemData[a].likes) : 0 
                this.comments += this.itemData[a].comments ? parseInt(this.itemData[a].comments) : 0
                this.share += this.itemData[a].share ? parseInt(this.itemData[a].share) : 0
                this.saved += this.itemData[a].saved ? parseInt(this.itemData[a].saved) : 0
              // }
            }
            this.level = [ ...new Set(this.level) ]
            setTimeout(() => { 
              this.isLoading = false
              this.loadingTable = false
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    downloadFile(invoice) {
      let token = localStorage.getItem('token')
      axios({
        url: process.env.VUE_APP_API_ENDPOINT + 'requestCampaign/exportOrder/'+this.order,
        headers: {
          'Authorization': 'Bearer '+ token
        },
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        const blob = new Blob([res.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = 'Report_VDC_'+invoice+'.xlsx';
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    levelFormat(value){
      if(value === 'A'){
        return 'Nano'
      } else {
        return 'Micro'
      }
    },
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  filters: {
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    labelFormat (value) {
      return value.replace(/-/g,' ');
    },
    dateFormat(value){
      return value.toString().slice(0, 24)
    },
    dateFormat2(value){
      return value.toString().slice(0, 16)
    },
    levelFormat(value){
      if(value === 'A'){
        return 'Nano'
      } else {
        return 'Micro'
      }
    }
  },
})
</script>